body{
  background-color: teal;
  background: teal;
  color: white;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.page{
  height: 100vh;
  overflow: auto;
}
.nav-container{
  display: flex;
  width: 100%;
}
.nav-container [data-nav-link-container]{
  text-align: right;
  width: 100%;
  padding-right: 40px;
  margin-top: 20px;
}
[data-nav]{
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}
.nav-container [data-nav-underline]::after{
  color: red;
  position: absolute;
  content: '';
  bottom: 5px;
  left: 10px;
  width: 0;
  height: 3px;
  background-color: white;
  transition: 0.5s width;
}
.nav-container [data-nav-underline]:hover::after{
  width: calc(100% - 20px);
}
.nav-container [data-menu]{
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 35px;
  display: none;
  z-index: 8000;
}
[data-work-with-us-btn]{
  background-color: red;
  color: white !important;
  border-radius: 15px;
  z-index: 2000;
}
[data-work-with-us-btn]:hover{
  background-color: rgb(233, 2, 2) !important;
}
.nav-container [data-catch-phrase]{
  margin-top: -20px;
  margin-left: 220px;
  transition: 0.5s all;
  white-space: nowrap;
}
[data-nav-active=true]::after{
  background-color: red !important;
  border-radius: 50%;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 10px !important;
  height: 10px !important;
}
[data-nav-active=true]:hover{
  color: white !important;
  cursor: default;
}
[data-larg-home-text]{
  font-size: 44px;
  font-weight: bold;
  margin-top: 40px;
  line-height: 10px;
  transition: 0.5s all;
}
[data-ship-container]{
  text-align: right;
  margin-top: -150px;
  transition: 0.5s all;
  position: relative;
}
[data-ship-container] img{
  width: 70%;
  height: 100%;
  user-select: none;
}
[data-home-head]{
  position: relative;
  margin: 20px;
}
[data-home-head] [data-nav]{
  position: absolute;
  left: 0;
  top: 100px;
}
[data-card-row]{
  background: white;
  background-color: white;
  padding: 20px;
  text-align: center;
}
[data-card-row] > div{
  background: teal;
  background-color: teal;
  padding: 15px;
  width: 330px;
  border-radius: 10px;
  display: inline-flex;
  margin: 5px 10px;
  height: 120px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s all;
}
[data-card-row] > span{
  width: 990px;
  transition: 1.5s all;
}
[data-card-row] svg{
  width: 50px;
  height: 50px;
}
[data-sub-text]{
  font-size: 25px;
  font-weight: bold;
  padding: 10px 30px;
}
[data-icon-img] img{
  width: 80px;
  height: 80px;
}
textarea{
  resize: none;
}
.contact-bg{
  background-size: cover;
  background-image: url(../images/contact-bg.png);
}
.bg-divider-opicity{
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.2);
}
.hide-on-mobile{
  display: block;
}
.show-on-mobile{
  display: none;
}
.card-selected{
  cursor: default !important;
  color: black !important;
  background: white !important;
  background-color: white !important;
}