@media screen and (max-width:1270px){
    [data-ship-container]{
        margin-top: 0px;
    }
}

@media screen and (max-width:1120px){
    [data-card-row] > div{
        width: calc(33.33% - 50px);
    }
    [data-card-row] > span{
      width: calc(99.99% - 50px);
    }
}

@media screen and (max-width:767px){
    [data-card-row] > div{
        width: calc(100% - 50px);
    }
    [data-card-row] > span{
      width: calc(100% - 50px);
    }
    .contact-bg{
        margin: 100px 0;
    }
    .hide-on-mobile{
        display: none !important;
    }
    .show-on-mobile{
      display: block;
    }
}

@media screen and (max-width:700px){
    .logo{
        width: 264px;
        height: 120px;
    }
    .nav-container [data-catch-phrase]{
        margin-top: -35px;
        margin-left: 150px;
        transition: 0.5s all;
    }
    [data-larg-home-text]{
      font-size: 34px;
      line-height: 0px;
    }
}

@media screen and (max-width:580px){
    .nav-container [data-nav-link-container]{
        top: 0;
        color: black;
        text-align: left;
        background-color: white;
        position: fixed;
        padding: 20px 0;
        right: 0;
        margin: 0;
        width: 200px;
        height: 100vh;
        z-index: 1500;
        display: none;
        transition: 0.5s all;
    }
    .nav-container [data-backdrop]{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 1000;
        display: none;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .nav-container [data-nav]{
        white-space: nowrap;
        display: block;
    }
    .show-nav{
        display: block !important;
    }
    .nav-container [data-menu]{
      display: block;
    }
    .logo{
        width: 200px;
        height: 80px;
        transition: 0.5s all;
    }
    .nav-container [data-catch-phrase]{
        margin-top: -20px;
        margin-left: 95px;
    }
    [data-larg-home-text]{
      font-size: 24px;
      white-space: nowrap;
      margin-bottom: 10px;
    }
    [data-home-head] [data-nav]{
      top: 60px;
      font-size: 15px;
    }
}
